import { Box, Spinner } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react";
import { getMatchesForRound, getTipsForRound, submitTipForMatch } from "../../requests/matches";
import { useNavigate } from "react-router-dom";
import { Nav, NavItem } from "../../components/Nav";
import { Round } from "../../components/Round";


export const Home = () => {
    const [loadingMatches, setLoadingMatches] = useState(false);
    const [clubs, setClubs] = useState<Record<string, Club> | null>(null);
    const [matches, setMatches] = useState<Match[] | null>(null);
    const [tipSpread, setTipSpread] = useState<Record<string, TipSpread> | null>(null);
    const [round, setRound] = useState<number | null>(null);
    const [loadingTips, setLoadingTips] = useState(false);
    const [tips, setTips] = useState<Tip[] | null>(null);

    const navigate = useNavigate();
    

    const getTips = useCallback(async(r: number) => {
        setLoadingTips(true);
        try{
            const { tips: t } = await getTipsForRound(r);
            setTips(t);
        } catch (err) {
            console.log('err', err);
        }
        setLoadingTips(false);
    }, [])

    const getMatches = useCallback(async() => {
        setLoadingMatches(true);
        try{
            const {round: r, matches: m, tipSpread: ts, clubs: c} = await getMatchesForRound();
            setRound(r);
            setMatches(m);
            setTipSpread(ts);
            setClubs(c);
        } catch (err) {
            console.log('err', err);
            navigate('/auth');
        }
        setLoadingMatches(false);
    }, [navigate])

    const submitTip = async(matchId: string, tipPick: TipPick) => {
        try{
            if (!round){
                // TODO: something better than this
                throw Error("No round");
            }
            const tip = await submitTipForMatch(round, matchId, tipPick);
            setTips([
                ...(tips || []),
                tip,
            ])
            getMatches();
        } catch (err) {
            console.log('err', err);
            // TODO: if unauthed, maybe modal.
            // Either error message or sign in form depending on error type?
        }
    }

    useEffect(() => {
        getMatches();
    }, [getMatches]);

    useEffect(() => {
        if(round){
            getTips(round);
        }
    }, [round, getTips])

    return (
        <Box w="100%">
            <Nav>
                <NavItem to="/past">Past</NavItem>
                <NavItem to="/">Round {round || "..."}</NavItem>
                <NavItem to="/upcoming" isDisabled>Upcoming</NavItem>
            </Nav>
            <Box py={4}>
                {loadingMatches && (
                    <Spinner />
                )}
                {!!matches && !!matches.length && !!round && (
                <Round
                    isLoadingTips={loadingTips}
                    clubs={clubs || {}}
                    matches={matches}
                    tipSpread={tipSpread || {}}
                    tips={tips}
                    onSelect={submitTip}
                />
                )}
            </Box>
            {/* <Box>
                Leaderboard
            </Box> */}
        </Box>
    )
}