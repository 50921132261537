import { ChangeEvent, FormEvent, useState } from "react";
import { Field } from "../../components/Field";
import { joinComp } from "../../requests/comp";
import { useNavigate } from "react-router-dom";
import { Alert, AlertIcon, Button, Text } from "@chakra-ui/react";
import { FormLayout } from "../../scaffolds/FormLayout";
import { FetchError } from "../../requests/errors";

export const CompJoin = () => {
    const [compId, setCompId] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();

    const onChangeCompId = (ev: ChangeEvent) => {
        setCompId((ev.target as HTMLInputElement).value);
    }

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);
        try{
            await joinComp(compId);
            navigate('/');
        } catch (err) {
            if(err instanceof Error){
                console.error(err.message);
            }
            if(err instanceof FetchError){
                if(err.status === 401){
                    navigate('/auth');
                } else if(err.status === 400){
                    setError('Invalid comp ID');
                }
                
            }
        }
        setIsSubmitting(false);
    }

    return (
        <FormLayout onSubmit={onSubmit}>
            <Text textAlign="left" pb={4}>Enter the ID of the competition you would like to join.</Text>
            <Field isDisabled={isSubmitting} autoFocus placeholder="abc123" label="Comp ID" value={compId} onChange={onChangeCompId} />
            {!!error && (
                <Alert status="warning" mt={4}>
                    <AlertIcon />
                    {error}
                </Alert>
            )}
            <Button isLoading={isSubmitting} className="button" type="submit" colorScheme="blue" mt={8} w="100%">Join comp</Button>
        </FormLayout>
    )
}