import { Box } from "@chakra-ui/react"

type BarProps = {
    children: any;
}

export const Bar = ({children}: BarProps) => {

    return (
        <Box 
            bg="#131F2A" 
            w="100%" 
            p={2} 
            minH={14} 
            position="relative"
            display="flex" 
            justifyContent="center" 
            alignItems="center"
            color="#F1F5F9"
        >
            {children}
        </Box>
    )
}