import { API_URL } from "./consts";
import { FetchError } from "./errors";

type JoinCompResponse = {
    compId: string;
}

export const joinComp = async (compId: string): Promise<JoinCompResponse> => {
    const rawResponse = await fetch(`${API_URL}/comp/join`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({compId})
    });
    if(!rawResponse.ok) {
        const respJson = await rawResponse.json();
        throw new FetchError(rawResponse.status, respJson.error);
    }
    return rawResponse.json();
}

type GetCompsResponse =  {
    comps: Comp[];
};

export const getComps = async(): Promise<GetCompsResponse> => {
    const rawResponse = await fetch(`${API_URL}/comps`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    if(rawResponse.status !== 200) {
        throw await rawResponse.json();
    }
    return rawResponse.json();
}