import { Box } from "@chakra-ui/react"

type AvatarProps = {
    username: string;
    align: "left" | "right"
}

export const Avatar = ({username, align}: AvatarProps) => {
    const marginProps = {
        ml: "0",
        mr: "0",
    };
    if (align === "left") {
        marginProps.mr = "-5px";
    }
    if (align === "right") {
        marginProps.ml = "-5px";
    }
    return (
        <Box 
            bg="#F1F5F9"
            h={5}
            w={5}
            borderRadius={100}
            border="1px solid #1A2A38"
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize={"0.8rem"}
            {...marginProps}
        >
            {username.slice(0,1).toLocaleUpperCase()}
        </Box>
    )
}