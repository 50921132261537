import { Box, BoxProps, ButtonProps as ChakraButtonProps } from '@chakra-ui/react';
import './Button.css';

type ButtonVariants = 'solid' | 'outline' | 'ghost' 

type ButtonProps = {
    children: any;
    to?: string;
    variant?: ButtonVariants;
}

export const Button = ({
    children,
    disabled,
    variant,
    type,
    className,
    isLoading,
    as,
    ...others
}: ButtonProps & ChakraButtonProps & BoxProps) => {
    const defaultStyleProps = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        fontWeight: 'bold',
        bgColor: '#2768A5',
        color: '#F1F5F9',
        borderRadius: 5,
        p: 2,
        border: '2px solid #2768A5',
        opacity: 1,
    };

    if (variant === 'outline'){
        defaultStyleProps.bgColor = 'transparent';
        defaultStyleProps.color = '#2768A5';
        defaultStyleProps.border = '2px solid #2768A5';
    }

    if (variant === 'ghost'){
        defaultStyleProps.bgColor = 'transparent';
        defaultStyleProps.color = '#2768A5';
        defaultStyleProps.border = "none";
    }

    if (disabled){
        defaultStyleProps.opacity = 0.25;
    }

    return (
        <Box
            as={as || 'button'}
            className={`Button ${className || ''} ${variant || 'solid'}`} 
            isLoading={isLoading} 
            type={type || 'submit'}
            pointerEvents={disabled ? "none" : "initial"}

            {...defaultStyleProps}

            {...others}
        >
            {children}
        </Box>
    );
}