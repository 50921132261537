import { ChangeEventHandler } from 'react';
import { Box, Input } from '@chakra-ui/react';

type FieldProps = {
    autoFocus?: boolean;
    placeholder?: string;
    isDisabled?: boolean;
    type?: string;
    label: string;
    value: string;
    onChange: ChangeEventHandler,
}

export const Field = ({ autoFocus, placeholder, isDisabled, type, label, value, onChange }: FieldProps) => (
    <Box className="field" display="flex" flexDirection="column" alignItems="flex-start" py="0.5rem">
        <label>{label}</label>
        <Input isDisabled={isDisabled} autoFocus={autoFocus} placeholder={placeholder} type={type} value={value} w="100%" onChange={onChange} />
    </Box>
)