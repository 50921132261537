import { Heading } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import { AuthCreate } from '../pages/auth/AuthCreate';
import { AuthLogin } from '../pages/auth/AuthLogin';
import { Bar } from '../components/Bar';
import { AuthLayout } from './AuthLayout';

export const Auth = () => {
    return (
        <>
            <Bar>
                <Heading size="md">Shroom Tip</Heading>
            </Bar>
            <AuthLayout>
                <Routes>
                    <Route path="/" element={<AuthLogin />} />
                    <Route path="/create" element={<AuthCreate />} />
                </Routes>
            </AuthLayout>
        </>
    )
}