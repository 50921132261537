import { Route, Routes, useMatches } from "react-router-dom"
import { MenuBar } from "../components/MenuBar"
import { Home } from "../pages/home/Home"
import { CompJoin } from "../pages/comp/CompJoin"
import { MainLayout } from "./MainLayout"
import { Past } from "../pages/past/Past"
import { CompsList } from "../pages/comp/CompsList"

const pageTitleMap = {
    '/': 'Tips',
    '/past': 'Tips',
    '/upcoming': 'Tips',
    '/comps/join': 'Comps',
    '/comps': 'Comps'
} as Record<string, string>


export const Main = () => {
    const matches = useMatches();
    const matchedPathname = matches[0].pathname;
    let pageTitle = '';
    if(matchedPathname in pageTitleMap){
        pageTitle = pageTitleMap[matchedPathname];
    }

    return (
        <>
            <MenuBar title={pageTitle} />
            <MainLayout>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/past" element={<Past />} />
                    <Route path="/comps/" element={<CompsList />} />
                    <Route path="/comps/join" element={<CompJoin />} />
                </Routes>
            </MainLayout>
        </>
    )
}