import { Box } from "@chakra-ui/react";
import { FormEvent } from "react";

type FormProps = {
    children: any;
    onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
}

export const FormLayout = ({children, onSubmit}: FormProps) => {

    return (
        <Box
            as="form"
            onSubmit={onSubmit}
            className="form-layout"
            minW="300px" 
            w="100%" 
            maxW="420px"
        >
            {children}
        </Box>
    )
}