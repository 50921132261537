import { Box, Heading, Spinner, Stack } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getComps } from "../../requests/comp";
import { Leaderboard } from "../../components/Leaderboard";
import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import { Button } from "../../components/Button";
import { Card } from "../../components/Card";


export const CompsList = () => {
    const [isLoadingComps, setIsLoadingComps] = useState(false);
    const [comps, setComps] = useState<Comp[] | null>(null);

    const navigate = useNavigate();

    // const myUsername = 'jack'; // TODO:

    const getMatches = useCallback(async() => {
        setIsLoadingComps(true);
        try{
            const {comps: c} = await getComps();
            setComps(c);
        } catch (err) {
            console.log('err', err);
            navigate('/auth');
        }
        setIsLoadingComps(false);
    }, [navigate])

    useEffect(() => {
        getMatches();
    }, [getMatches]);

    return (
        <Stack spacing='4' w="100%">
            {isLoadingComps && (
                <Box py={4}>
                    <Spinner />
                </Box>
            )}
            {!!comps && comps.map(comp => (
                <Card key={comp.id} variant="elevated" p={4}>
                    <Heading size='xs'>
                        {comp.name}
                    </Heading>
                    <Stack py={2}>
                        {!!comp.users && (
                            <Leaderboard activeUsername={''} users={comp.users} />
                        )}
                    </Stack>
                </Card>
            ))}
            <Box
                display="flex"
                justifyContent="space-between"
            >
                <Button disabled variant="outline" mr={2}>
                    <Box as={AddIcon} mr={2} /> Create new comp
                </Button>
                <Button disabled w={20}>
                    <Box as={SearchIcon} />
                </Button>
            </Box>
        </Stack>
    )
}