import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerOverlay, Heading, IconButton, List, ListItem, useDisclosure } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { Bar } from "./Bar";
import { logout } from "../requests/auth";
import { MouseEvent } from "react";

type MenuBarProps = {
    title: string;
}

export const MenuBar = ({ title }: MenuBarProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();

    const onLogout = async (event: MouseEvent) => {
        event.preventDefault();
        try{
            await logout();
            navigate('/auth')
        } catch (err) {
            console.log('err', err);
        }
    }
    
    return (
        <Bar>
            <Heading size="md" w="100%">{title}</Heading>
            <Box 
                position="absolute" 
                right={0} 
                top={0} 
                bottom={0} 
                h="100%" 
                display="flex"
                alignItems="center"
            >
                <IconButton aria-label="Menu" colorScheme="white" size="lg" icon={<HamburgerIcon />} onClick={onOpen} />
            </Box>

            <Drawer isOpen={isOpen} onClose={onClose} placement="top">
                <DrawerOverlay />
                <DrawerContent bg="#1A2A38" color="white">
                    <DrawerCloseButton p={2} />
                    {/* <DrawerHeader>
                        
                    </DrawerHeader> */}

                    <DrawerBody>
                        <List 
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            py={4}
                        >
                            <ListItem as={Heading} size="md" py={4}>
                                <Link to="/" onClick={onClose}>Tips</Link>
                            </ListItem>
                            <ListItem as={Heading} size="md" py={4}>
                                <Link to="/comps" onClick={onClose}>Comps</Link>
                            </ListItem>
                            <ListItem as={Heading} size="md" py={4}>
                                <Link to="/account" onClick={onClose}>Account</Link>
                            </ListItem>
                            <Divider my={4}/>
                            <ListItem as={Heading} size="md" py={4}>
                                <Link to="/" onClick={onLogout}>Logout</Link>
                            </ListItem>
                        </List>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </Bar>
    );
}