import { Box } from "@chakra-ui/react";

const bgColorMap = [
    "palegoldenrod",
    "lightgrey",
    "#D2AB8D"
]

const colorMap = [
    "darkgoldenrod",
    "slategrey",
    "sienna"
]

type PositionIconProps = {
    position: number;
}

export const PositionIcon = ({position}: PositionIconProps) => {

    return (
        <Box
            height={6}
            width={6}
            borderRadius={100}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgColor={bgColorMap[position - 1]}
            color={colorMap[position - 1]}
            fontSize={16}
            fontWeight="bold"
        >
            {position}
        </Box>
    )
}