import { Tab, TabList, Tabs } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import './Nav.css'

type NavProps = {
    children: any;
}

export const Nav = ({ children }: NavProps) => (
    <Tabs className="nav">
        <TabList className="nav-list" display="flex" justifyContent="center" alignItems="center">
            {children}
        </TabList>
    </Tabs>
)

type NavItemProps = {
    children: any;
    to: string;
    isDisabled?: boolean;
}

export const NavItem = ({ to, isDisabled, children }: NavItemProps) => (
    <Tab
        className="nav-item"
        as={NavLink}
        end
        to={to}
        isDisabled={isDisabled}
    >
        {children}
    </Tab>
)