import { ChangeEvent, FormEvent, useState } from "react";
import { Field } from "../../components/Field";
import { Alert, AlertIcon, Box, Text } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../requests/auth";
import { Button } from "../../components/Button";

export const AuthLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();

    const onChangeUsername = (ev: ChangeEvent) => {
        setUsername((ev.target as HTMLInputElement).value);
    }
    const onChangePassword = (ev: ChangeEvent) => {
        setPassword((ev.target as HTMLInputElement).value);
    }

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);
        try{
            await login(username, password);
            navigate('/')
        } catch (err) {
            console.log('err', err);
            setError('Username or password is incorrect');
        }
        setIsSubmitting(false);
    }

    return (
        <Box as="form" onSubmit={onSubmit} minW="320px" w="100%" maxW="420px">
            <Field isDisabled={isSubmitting} autoFocus label="Username" value={username} onChange={onChangeUsername} />
            <Field isDisabled={isSubmitting} type="password" label="Password" value={password} onChange={onChangePassword} />
            {!!error && (
                <Alert status="warning" mt={4}>
                    <AlertIcon />
                    {error}
                </Alert>
            )}
            <Button isLoading={isSubmitting} my={8}>Login</Button>
            <Text fontSize="xs">Don't have an account yet?</Text>
            <Button as={Link} to="/auth/create" variant="ghost">Create a new account</Button>
        </Box>
    )
}

