import { API_URL } from "./consts";

type GetMatchesForRoundResponse =  {
    round: number;
    matches: Match[];
    tipSpread: Record<string, TipSpread>;
    clubs: Record<string, Club>;
};

export const getMatchesForRound = async(round?: number): Promise<GetMatchesForRoundResponse> => {
    const requestedRound = round || 'next';
    const rawResponse = await fetch(`${API_URL}/round/${requestedRound}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    if(rawResponse.status !== 200) {
        throw await rawResponse.json();
    }
    return rawResponse.json();
}

type GetMatchesForRoundsResponse =  {
    rounds: GetMatchesForRoundResponse[]
};

export const getMatchesForRounds = async(batch: 'past' | 'upcoming'): Promise<GetMatchesForRoundsResponse> => {
    const rawResponse = await fetch(`${API_URL}/rounds/${batch}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    if(rawResponse.status !== 200) {
        throw await rawResponse.json();
    }
    return rawResponse.json();
}

export const submitTipForMatch = async(round: number, matchId: string, tipPick: TipPick): Promise<Tip> => {
    const rawResponse = await fetch(`${API_URL}/round/${round}/tips`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            matchId,
            tip: tipPick,
        })
    });
    if(rawResponse.status !== 200) {
        throw await rawResponse.json();
    }
    return rawResponse.json();
}


type GetTipsForRoundResponse =  {
    tips: Tip[];
};

export const getTipsForRound = async(round: number): Promise<GetTipsForRoundResponse> => {
    const rawResponse = await fetch(`${API_URL}/round/${round}/tips`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    });
    if(rawResponse.status !== 200) {
        throw await rawResponse.json();
    }
    return rawResponse.json();
}

type GetPastTipsResponse =  {
    rounds: Record<number, Tip[]>
};

export const getPastTips = async(): Promise<GetPastTipsResponse> => {
    const rawResponse = await fetch(`${API_URL}/rounds/past/tips`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    if(rawResponse.status !== 200) {
        throw await rawResponse.json();
    }
    return rawResponse.json();
}