import {
  createBrowserRouter,
} from "react-router-dom";
import { Auth } from "./scaffolds/Auth";
import { Main } from "./scaffolds/Main";
// import { NotFound } from "./pages/NotFound";
// import { CompHome, loader as compLoader } from "./pages/CompHome";


export const router = createBrowserRouter([
  {
    path: "/*",
    element: <Main />,
  },
  {
    path: "/auth/*",
    element: <Auth />
  },
]);

