import { AbsoluteCenter, Box, Divider, Heading, Spinner } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react";
import { getMatchesForRounds, getPastTips } from "../../requests/matches";
import { useNavigate } from "react-router-dom";
import { Nav, NavItem } from "../../components/Nav";
import { PastRound } from "../../components/PastRound";

type RoundType = {
    round: number;
    matches: Match[];
}

export const Past = () => {
    const [loadingMatches, setLoadingMatches] = useState(false);
    const [rounds, setRounds] = useState<RoundType[] | null>(null);
    const [currentRound, setCurrentRound] = useState<number | null>(null);
    const [loadingTips, setLoadingTips] = useState(false);
    const [tipsByRound, setTipsByRound] = useState<Record<number, Tip[]> | null>(null);

    const navigate = useNavigate();

    const getTips = useCallback(async() => {
        setLoadingTips(true);
        try{
            const {rounds} = await getPastTips();
            setTipsByRound(rounds);
        } catch (err) {
            console.log('err', err);
        }
        setLoadingTips(false);
    }, [])

    const getMatches = useCallback(async() => {
        setLoadingMatches(true);
        try{
            const {rounds} = await getMatchesForRounds('past');
            setCurrentRound(rounds.length + 1);
            setRounds(rounds);
        } catch (err) {
            console.log('err', err);
            navigate('/auth');
        }
        setLoadingMatches(false);
    }, [navigate])


    useEffect(() => {
        getMatches();
    }, [getMatches]);

    useEffect(() => {
        if(currentRound){
            getTips();
        }
    }, [currentRound, getTips])

    return (
        <Box w="100%">
            <Nav>
                <NavItem to="/past">Past</NavItem>
                <NavItem to="/">Round {currentRound || "..."}</NavItem>
                <NavItem to="/upcoming" isDisabled>Upcoming</NavItem>
            </Nav>
            <Box py={4}>
                {(loadingMatches || loadingTips) && (
                    <Spinner />
                )}
                {!!rounds && !!rounds.length && !!tipsByRound && (
                    rounds.sort((a: RoundType, b: RoundType) => b.round - a.round).map(({round, matches}, index) => (
                        <Box key={round} py={4}>
                            <Box position='relative' mb={12} mt={index === 0 ? 4 : 8}>
                                <Divider />
                                <AbsoluteCenter px='4'>
                                    <Heading size="lg">Round {round}</Heading>
                                </AbsoluteCenter>
                            </Box>
                            {!!matches && !!matches.length && (
                                <PastRound
                                    matches={matches}
                                    tips={tipsByRound ? tipsByRound[round] : null}
                                />
                            )}
                        </Box>
                    ))
                )}
            </Box>
            {/* <Box>
                Leaderboard
            </Box> */}
        </Box>
    )
}