import { Box } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';

export const IncorrectIcon = () => (
    <Box
        borderRadius={100}
        bg="darkred"
        height={4}
        width={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
    >
        <CloseIcon color="white" fontSize={8} />
    </Box>
)