import { Box } from "@chakra-ui/react"

type SpreadBarProps = {
    homeCount: number;
    homeColours?: Club;
    awayCount: number;
    awayColours?: Club;
}

export const SpreadBar = ({homeCount, homeColours, awayCount, awayColours}: SpreadBarProps) => {
    const homeColour1 = homeColours?.colour1 || "#D5E1EC";
    const homeColour2 = homeColours?.colour2 || "#D5E1EC";

    const awayColour1 = awayColours?.colour1 || "#D5E1EC";
    const awayColour2 = awayColours?.colour2 || "#D5E1EC";

    return (
        <Box 
            h="3px"
            w="100%"
            display="flex"
            opacity="0.75"
        >
            {!!homeCount && (
                <Box 
                    bg={homeColour1} 
                    h="100%" 
                    flexGrow={homeCount} 
                    mr="8px"
                    borderRadius={4}
                    borderTop="3px dashed transparent"
                    borderTopColor={homeColour2}
                    boxShadow={`0 0 0 1px ${'#131F2A'}`}
                />
            )}
            {!!awayCount && (
                <Box 
                    bg={awayColour1} 
                    h="100%" 
                    flexGrow={awayCount}
                    borderRadius={4}
                    borderTop="3px dashed transparent"
                    borderTopColor={awayColour2}
                    boxShadow={`0 0 0 1px ${'#131F2A'}`}
                />
            )}
        </Box>
    )
}