import { Box, Heading, Stack } from "@chakra-ui/react"
import { Match } from "./Match";
import { useMemo } from "react";
import { isDateToday } from "../utils/datetime";
import { Card } from "./Card";


const dayMap = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat'
];

const monthMap = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
]

const formatDate = (date: Date) => {
    return `${dayMap[date.getDay()]}, ${date.getDate()} ${monthMap[date.getMonth()]}`
}

const sortByStartAt = (a: Match, b: Match) => {
    return new Date(a.startAt).getTime() - new Date(b.startAt).getTime();
}

type RoundProps = {
    isLoadingTips: boolean;
    clubs: Record<string, Club>;
    matches: Match[];
    tipSpread: Record<string, TipSpread>;
    tips: Tip[] | null;
    onSelect?: (matchId: string, tipPick: TipPick) => {}
}

type MatchesGroup = {
    dateIndex: number;
    title: string;
    matches: Match[];
}

export const Round = ({ isLoadingTips, clubs, matches, tipSpread, tips, onSelect: onSubmit }: RoundProps) => {
    const tipsMap = tips?.reduce((prev, curr) => ({
        ...prev,
        [curr.matchId]: curr.tip,
    }), {}) as Record<string, TipPick>;

    const matchesGroupedByDay = useMemo(() => matches.reduce((prev, curr) => {
        const date = new Date(curr.startAt);
        const dateIndexDay = date.getDate();
        const dateIndex = Number(`${date.getMonth()}.${dateIndexDay < 10 ? `0${dateIndexDay}` : dateIndexDay}`);
        const formattedDate = isDateToday(date) ? "Today" : formatDate(date);
        const currentGroup = prev[dateIndex];
        const currentMatchesForGroup = currentGroup?.matches || [];
        return {
            ...prev,
            [dateIndex]: {
                dateIndex,
                title: formattedDate,
                matches: [
                    ...(currentMatchesForGroup || []),
                    {
                        ...curr,
                        homeClub: clubs[curr.homeTeam],
                        awayClub: clubs[curr.awayTeam],
                    },
                ]
            }
        };
    }, {} as Record<string, MatchesGroup>), [matches, clubs])

    const orderedMatchGroups = useMemo(() => Object.values(matchesGroupedByDay).sort((a: MatchesGroup, b: MatchesGroup) => a.dateIndex - b.dateIndex), [matchesGroupedByDay]);

    return (
        <Stack spacing='4'>
            {!!orderedMatchGroups && !!orderedMatchGroups.length && orderedMatchGroups.map((orderedMatchGroup) => (
                <Box as={Card} key={orderedMatchGroup.dateIndex} variant="elevated" p={4}>
                    <Heading fontSize='xs' flexShrink={0}>{orderedMatchGroup.title}</Heading>
                    {!!orderedMatchGroup.matches && orderedMatchGroup.matches.sort(sortByStartAt).map(match => (
                        <Match
                            key={match.id}
                            match={match}
                            tipSpread={tipSpread[match.id] || {}}
                            isLoadingSelection={isLoadingTips}
                            selectedTeam={(!!tipsMap && match.id in tipsMap && tipsMap[match.id]) || undefined} 
                            onSelectTeam={onSubmit} 
                        />
                    ))}
                </Box>
            ))}
            
        </Stack>
    )
}