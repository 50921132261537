import { Box, Button } from "@chakra-ui/react";
import { MouseEvent } from "react";
import './TeamSelectButton.css';

type TeamSelectButtonProps = {
    isHistorical?: boolean;
    isCorrect?: boolean;
    isDisabled: boolean;
    isLoading: boolean;
    className?: string;
    onClick: (ev: MouseEvent<HTMLButtonElement>) => void;
    children: any;
}

export const TeamSelectButton = ({ isHistorical, isCorrect, isDisabled, isLoading, className, onClick, children }: TeamSelectButtonProps) => {
    const onSelectFn = (ev: MouseEvent<HTMLButtonElement>) => {
        onClick(ev);
    }
    
    let colorScheme = 'blue';
    let color = 'rgb(43, 108, 176)';
    if(isHistorical){
        colorScheme = isCorrect ? 'green' : 'red';
        color = isCorrect ? 'rgb(47, 133, 90)' : 'rgb(229, 62, 62)';
    }

    return (
        <Box 
            as={Button}
            className={className}
            variant="outline"
            colorScheme={colorScheme}
            display="flex" 
            justifyContent="space-between" 
            alignItems="center" 
            width={"100%"}
            height={12}
            borderColor={color}
            color={color}
            onClick={onSelectFn}
            isDisabled={isDisabled}
            isLoading={isLoading}
        >
            {children}
        </Box>
    )
}