import { API_URL } from "./consts";
import { FetchError } from "./errors";

type LoginResponse = {
    token: string;
}

export const login = async(username: string, password: string): Promise<LoginResponse> => {
    const rawResponse = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({username, password})
    });
    if(!rawResponse.ok) {
        const respJson = await rawResponse.json();
        throw new FetchError(rawResponse.status, respJson.error);
    }
    return rawResponse.json();
}

type CreateAccountResponse = {
    token: string;
}

export const createAccount = async(username: string, password: string): Promise<CreateAccountResponse> => {
    const rawResponse = await fetch(`${API_URL}/auth/create`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({username, password})
    });
    if(!rawResponse.ok) {
        const respJson = await rawResponse.json();
        throw new FetchError(rawResponse.status, respJson.error);
    }
    return rawResponse.json();
}

type LogoutResponse = {
}

export const logout = async(): Promise<LogoutResponse> => {
    const rawResponse = await fetch(`${API_URL}/auth/logout`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
    });
    if(!rawResponse.ok) {
        const respJson = await rawResponse.json();
        throw new FetchError(rawResponse.status, respJson.error);
    }
    return rawResponse.json();
}