import { Box, BoxProps, CardProps as ChakraCardProps } from '@chakra-ui/react';
import './Button.css';

type CardProps = {
    children: any;
    className?: string;
}

export const Card = ({
    children,
    className,
    ...others
}: CardProps & BoxProps & ChakraCardProps) => {

    return (
        <Box
            className={`Card ${className || ''}`}
            border="2px solid #D5E1EC"
            borderRadius={5}
            {...others}
        >
            {children}
        </Box>
    );
}