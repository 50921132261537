import { Box } from "@chakra-ui/react";

type MainProps = {
    children: any;
}

export const MainLayout = ({children}: MainProps) => {

    return (
        <Box 
            className="main-layout" 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            p={2}
            w="100%"
            maxW="480px"
        >
            {children}
        </Box>
    )
}