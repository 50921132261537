import { Box, Text } from "@chakra-ui/react";
import { PositionIcon } from "./PositionIcon";

type LeaderboardProps = {
    activeUsername: string;
    users: CompUser[];
}

export const Leaderboard = ({activeUsername, users}: LeaderboardProps) => {
    const usersOrderedByScore = users.map((user) => ({
        username: user.username,
        scores: user.scores,
        totalScore: user.scores?.reduce((prev,curr) => prev+curr.points, 0) || 0
    }), {}).sort((a, b) => b.totalScore - a.totalScore);

    return (
        <Box w="100%">
            {usersOrderedByScore.map(({username, scores, totalScore}, index) => (
                <Box 
                    key={username}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    textAlign="left"
                    p={2}
                    fontWeight={username === activeUsername ? "bold" : "normal"}
                    borderTop={index !== 0 ? "1px solid #D5E1EC" : ""}
                    bgColor={username === activeUsername ? "rgba(43, 108, 176, 0.1)" : ""}
                >
                    <Box w={14}><PositionIcon position={index+1} /></Box>
                    <Box w="100%"><Text>{username}</Text></Box>
                    <Box>{totalScore}</Box>
                </Box>
            ))}
        </Box>
    )
}
