import { Box, Text } from "@chakra-ui/react"

type OddsOrScoreProps = {
    hasStarted: boolean;
    odds: string;
    score: number;
}

export const OddsOrScore = ({ hasStarted, odds, score }: OddsOrScoreProps) => {
    const displayNumber = hasStarted ? score : odds;
    return (
        <Box>
            <Box as={Text} fontSize="xs" pt={1}>{hasStarted ? '' : '$'}{displayNumber}</Box>
        </Box>
    )
}
