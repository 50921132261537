import { Box, Img, Text } from "@chakra-ui/react";
import { TeamSelectButton } from "./TeamSelectButton";
import { useMemo } from "react";
import { CorrectIcon } from "./CorrectIcon";
import { IncorrectIcon } from "./IncorrectIcon";

type TeamSelectProps = {
    hasEnded: boolean;
    isDisabled: boolean;
    isLoading: boolean;
    name: string;
    isSelected?: boolean;
    isCorrect?: boolean;
    onSelect: () => void
}

export const TeamSelect = ({ hasEnded, isDisabled, isLoading, name, isSelected, isCorrect, onSelect }: TeamSelectProps) => {
    const ResultIcon = useMemo(() => isCorrect ? CorrectIcon : IncorrectIcon, [isCorrect])
    return (
        <TeamSelectButton
            className={`team-select-button ${isSelected ? 'selected' : ''} ${hasEnded ? (isCorrect ? 'correct' : 'incorrect') : ''}`}
            onClick={onSelect}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isHistorical={hasEnded}
            isCorrect={isCorrect}
        >
            <Box display="flex" 
            justifyContent="flex-start" 
            alignItems="center" >
                <Box flexShrink={0} mr={2}>
                    <Img height={8} width={8} src={`https://www.nrl.com/.theme/${name.replace(' ', '-').toLowerCase()}/badge-basic24.svg`} />
                </Box>
                <Box as={Text} fontSize="sm">{name}</Box>
            </Box>
            {hasEnded && isSelected && (
                <ResultIcon />
            )}
        </TeamSelectButton>
    )
}