import { Box, Img } from "@chakra-ui/react"

type PastRoundProps = {
    matches: Match[];
    tips: Tip[] | null;
}

export const PastRound = ({matches, tips}: PastRoundProps) => {
    const tipsMap = tips?.reduce((prev, curr) => ({
        ...prev,
        [curr.matchId]: curr.tip,
    }), {}) as Record<string, TipPick>;

    const sortedMatches = matches.sort((a, b) => new Date(a.startAt).getTime() - new Date(b.startAt).getTime());
    
    return (
        <Box>
            {sortedMatches.map((match, i) => (
                <Box 
                    key={match.id}
                    display="flex"
                    justifyContent="space-between"
                    borderTop={i === 0 ? "" : "1px solid #E3EBF2"}
                    px={4}
                    py={1}
                >
                    <PastMatchTeam 
                        name={match.homeTeam} 
                        isSelected={(!!tipsMap && match.id in tipsMap && tipsMap[match.id]) === "home"} 
                        didWin={match.homeScore > match.awayScore}
                    />
                    <PastMatchTeam 
                        name={match.awayTeam}
                        isSelected={(!!tipsMap && match.id in tipsMap && tipsMap[match.id]) === "away"} 
                        didWin={match.homeScore < match.awayScore}
                    />
                </Box>
            ))}
        </Box>
    )
}

type PastMatchTeamProps = {
    name: string;
    isSelected: boolean;
    didWin: boolean;
}

const PastMatchTeam = ({name, isSelected, didWin}: PastMatchTeamProps) => {
    let bgColor = "transparent"
    if (isSelected){
        if(didWin){
            bgColor = "#B4E4C5"
        } else {
            bgColor = "#ECACB6"
        }
    }
    return (
        <Box w={"40%"} display="flex" alignItems="center" justifyContent="space-between" py={1}>
            <Img height={6} width={6} mr={2} src={`https://www.nrl.com/.theme/${name.replace(' ', '-').toLowerCase()}/badge-basic24.svg`} />
            {name}
            <Box borderRadius={100} h={4} w={4} border="2px solid" borderColor={didWin ? "#256A3D" : "#B72A3F"} bgColor={bgColor}/>
        </Box>
    );
}
