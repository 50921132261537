import { Box } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

export const CorrectIcon = () => (
    <Box
        borderRadius={100}
        bg="green"
        height={4}
        width={4}
        display="flex"
        justifyContent="center"
        alignItems="center"
    >
        <CheckIcon color="white" fontSize={8} />
    </Box>
)