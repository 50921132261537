import {
  ChakraBaseProvider,
  extendBaseTheme,
  theme as chakraTheme,
} from '@chakra-ui/react'
import {
  RouterProvider,
} from "react-router-dom";
import { router } from './router';
import './App.css';

const {
  Button, 
  Card,
  Tabs,
  Heading, 
  Spinner,
  Input,
  Alert,
  Drawer,
  Badge,
  Divider,
  Tag,
  Progress,
} = chakraTheme.components

const theme = extendBaseTheme({
  components: {
    Button, 
    Card,
    Tabs,
    Heading, 
    Spinner,
    Input,
    Alert,
    Drawer,
    Badge,
    Divider,
    Tag,
    Progress,
  },
})

function App() {
  return (
    <ChakraBaseProvider theme={theme}>
      <div className="app">
        <RouterProvider router={router} />
      </div>
    </ChakraBaseProvider>
  );
}

export default App;
