import { Box } from "@chakra-ui/react";

type AuthProps = {
    children: any;
}

export const AuthLayout = ({children}: AuthProps) => {

    return (
        <Box 
            className="auth-layout" 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            p={8}
            w="100%"
            bgColor="#F1F5F9"
        >
            {children}
        </Box>
    )
}