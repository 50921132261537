import { Badge, Box, Text } from "@chakra-ui/react";
import { TeamSelect } from "./TeamSelect";
import { useEffect, useMemo, useState } from "react";
import { isDateToday } from "../utils/datetime";
import { OddsOrScore } from "./OddsOrScore";
import { Avatar } from "./Avatar";
import { SpreadBar } from "./SpreadBar";


const formatDate = (date: Date) => {
    const mins = date.getMinutes();
    const formattedMins = mins < 10 ? `0${mins}` : mins;
    const hours = date.getHours();
    const formattedHours = hours < 13 ? hours : hours - 12;
    const amPm = hours < 12 ? 'am' : 'pm';
    return `${formattedHours}:${formattedMins}${amPm}`
}

const countdownTimer = (date: Date) => {
    const timeUntilMatch = date.getTime() - Date.now();
    if(timeUntilMatch < 0){
        return {
            countdown: '',
            urgency: '',
        }
    }

    const hoursUntilMatch = timeUntilMatch / 1000 / 60 / 60;

    let urgency = 'yellow';
    if(hoursUntilMatch < 2) {
        urgency = 'orange';
    }
    if(hoursUntilMatch < 1) {
        urgency = 'red';
    }

    return {
        countdown: `${Math.floor(hoursUntilMatch)}h ${Math.floor(60 * (hoursUntilMatch % 1))}m`,
        urgency,
    };

}

type MatchProps = {
    match: Match;
    tipSpread: TipSpread;
    isLoadingSelection: boolean;
    selectedTeam?: TipPick;
    onSelectTeam?: (matchId: string, tipPick: TipPick) => {};
}

export const Match = ( {isLoadingSelection, match, tipSpread, selectedTeam, onSelectTeam }: MatchProps) => {
    const [countdown, setCountdown] = useState<string>('');
    const [urgency, setUrgency] = useState<string>('');
    const [submittingTip, setSubmittingTip] = useState(false);


    const onSelectHome = async () => {
        setSubmittingTip(true);
        if(onSelectTeam){
            await onSelectTeam(match.id, 'home');
        }
        setSubmittingTip(false);
    };

    const onSelectAway = async () => {
        setSubmittingTip(true);
        if(onSelectTeam){
            await onSelectTeam(match.id, 'away');
        }
        setSubmittingTip(false);
    }

    const startDateTime = useMemo(() => new Date(match.startAt), [match.startAt]);
    const timeUntilMatch = startDateTime.getTime() - Date.now();
    const isClosed = timeUntilMatch < 0;
    const hasEnded = (timeUntilMatch + (1000 * 60 * 110)) < 0;

    const isMatchToday = isDateToday(startDateTime);

    useEffect(() => {
        if(isMatchToday){
            const ticker = () => {
                const {
                    countdown,
                    urgency,
                } = countdownTimer(startDateTime);
                setCountdown(countdown);
                setUrgency(urgency);
                tickerTimeout = setTimeout(ticker, 15000);
            };
    
            let tickerTimeout = setTimeout(ticker, 100)
    
            return () => clearTimeout(tickerTimeout);
        }
    }, [isMatchToday, startDateTime])

    const selectedHome = selectedTeam === 'home';
    const selectedAway = selectedTeam === 'away';
    const homeWon = match.homeScore > match.awayScore && hasEnded;
    const awayWon = match.awayScore > match.homeScore && hasEnded;

    return (
        <Box key={match.id} py={2}>
            <Box position="relative">
                <Text fontSize='xs' flexShrink={0} color="#666" >{formatDate(startDateTime)}</Text>
                {isMatchToday ? <Badge colorScheme={urgency} textTransform="initial" position="absolute" right="0" top="0">{countdown}</Badge> : ''}
            </Box>

            <Box 
                display="flex"
                justifyContent="space-between"
                my="4px"
            >
                <Box display="flex" minH="20px">
                    {tipSpread.homeTippers.map(tipper => (
                        <Avatar key={tipper} username={tipper} align="left" />
                    ))}
                </Box>
                <Box display="flex" justifyContent="flex-end" minH="20px">
                    {tipSpread.awayTippers.map(tipper => (
                        <Avatar key={tipper} username={tipper} align="right" />
                    ))}
                </Box>
            </Box>
            
            <SpreadBar 
                homeCount={tipSpread.homeCount} 
                homeColours={match.homeClub}
                awayCount={tipSpread.awayCount} 
                awayColours={match.awayClub}
            />

            <Box display="flex" justifyContent="space-between" mt={1}>
                <Box w={"100%"} maxW={48} pr={"1%"}>
                    <TeamSelect
                        hasEnded={hasEnded}
                        isDisabled={isLoadingSelection || isClosed} 
                        isLoading={submittingTip} 
                        name={match.homeTeam} 
                        isSelected={selectedHome}
                        isCorrect={homeWon}
                        onSelect={onSelectHome} 
                    />
                    <OddsOrScore hasStarted={isClosed} odds={match.homeOdds} score={match.homeScore} />
                </Box>
                <Box w={"100%"} maxW={48} pl={"1%"}>
                    <TeamSelect 
                        hasEnded={hasEnded}
                        isDisabled={isLoadingSelection || isClosed} 
                        isLoading={submittingTip} 
                        name={match.awayTeam} 
                        isSelected={selectedAway} 
                        isCorrect={awayWon}
                        onSelect={onSelectAway} 
                    />
                    <OddsOrScore hasStarted={isClosed} odds={match.awayOdds} score={match.awayScore} />
                </Box>
            </Box>
        </Box>
    )
}