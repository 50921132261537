import { ChangeEvent, FormEvent, useState } from "react";
import { Field } from "../../components/Field";
import { Alert, AlertIcon, Button, Text } from "@chakra-ui/react";
import { createAccount } from "../../requests/auth";
import { Link, useNavigate } from "react-router-dom";
import { FormLayout } from "../../scaffolds/FormLayout";
import { FetchError } from "../../requests/errors";

export const AuthCreate = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();

    const onChangeUsername = (ev: ChangeEvent) => {
        setUsername((ev.target as HTMLInputElement).value);
    }
    const onChangePassword = (ev: ChangeEvent) => {
        setPassword((ev.target as HTMLInputElement).value);
    }

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsSubmitting(true);
        try{
            await createAccount(username, password);
            navigate('/comp/join');
        } catch (err) {
            if(err instanceof FetchError && err.status === 400){
                setError(err.message);
            }
        }
        setIsSubmitting(false);
    }

    return (
        <FormLayout onSubmit={onSubmit}>
            <Field isDisabled={isSubmitting} autoFocus label="Username" value={username} onChange={onChangeUsername} />
            <Field isDisabled={isSubmitting} type="password" label="Password" value={password} onChange={onChangePassword} />
            {!!error && (
                <Alert status="warning" mt={4}>
                    <AlertIcon />
                    {error}
                </Alert>
            )}
            <Button className="button" type="submit" colorScheme="blue" my={8} w="100%" isLoading={isSubmitting}>Create account</Button>
            <Text fontSize="xs">Already have an account?</Text>
            <Button as={Link} to="/auth" className="button" variant="ghost" type="submit" colorScheme="blue" w="100%">Login</Button>
        </FormLayout>
    )
}
